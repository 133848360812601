<template>
  <div>
    <template v-if="showMenu">
      <ul class="nav">
        <li
          :class="{
            selected: currentPath == '/sales/salesReps/home' ? true : false,
          }"
        >
          <router-link to="/sales/salesReps/home" :class="[lang]">
            {{ $t("home") }}</router-link
          >
        </li>
        <li
          :class="{
            selected: currentPath == '/sales/salesReps/campaign' ? true : false,
          }"
        >
          <router-link to="/sales/salesReps/campaign" :class="[lang]">{{
            $t("campaigns")
          }}</router-link>
        </li>
        <li
          :class="{
            selected:
              currentPath == '/sales/salesReps/dashboard' ? true : false,
          }"
        >
          <router-link to="/sales/salesReps/dashboard" :class="[lang]">{{
            $t("dashboard")
          }}</router-link>
        </li>
        <li
          :class="{
            selected: currentPath == '/sales/salesReps/linkin' ? true : false,
          }"
        >
          <router-link to="/sales/salesReps/linkin" :class="[lang]">{{
            $t("linkedinAccountSetting")
          }}</router-link>
        </li>
      </ul>
    </template>
    <template v-else>
      <p class="back">
        <span @click="goback"
          ><a-icon type="left" /><span :class="[lang]">{{
            $t("back")
          }}</span></span
        >
      </p>
    </template>
    <!-- <keep-alive v-if="$route.meta.KeepAlive">
      <router-view></router-view>
    </keep-alive> -->
    <keep-alive>
      <router-view
        v-if="$route.meta.KeepAlive"
        :exclude="['createCampaign', '/sales/salesReps/createCampaign']"
        :key="$route.path"
      />
    </keep-alive>
    <router-view v-if="!$route.meta.KeepAlive"></router-view>
    <div class="mask_bg" v-if="showGuide">
      <a-icon
        @click="closeGuide"
        type="close"
        style="
          color: #fff;
          position: fixed;
          right: 20px;
          top: 20px;
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
        "
      />
      <div class="title">How to set up your first AI Sales Rep?</div>
      <div class="caption" style="cursor: inherit">
        <div class="text">
          {{ $t("campaigns") }}
        </div>
        <div class="des">1.First, click "Campaigns" to open the AI Sales task list.</div>
      </div>
      <div class="btn">
        <span
          type="primary"
          :class="[lang]"
          class="guideBtn"
          size="large"
          style="cursor: inherit"
        >
          {{ $t("Create") }}
        </span>
        <span class="des">2.Next, click "Create" to set up your AI Sales Rep.</span>
      </div>
      <div class="guide-con">
        <div>While setting up your AI Sales Rep:</div>
        <div>A. Add a LinkedIn account for AI to use.</div>
        <div>B. Provide product and company info for lead generation.</div>
        <div
          style="margin-bottom: 20px"
        >C. Add LinkedIn search links for potential clients.</div>
        <div>Complete these steps to start AI-powered sales.</div>
        <div class="con-click" @click="goPage">Click to watch the demo video.</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
      showGuide: false,
    };
  },
  i18n: require("./i18n"),

  watch: {
    $route(to, from) {
      if (to.path == "/sales/salesReps/dialogue") {
        this.showMenu = false;
      } else if (to.path == "/sales/salesReps/createCampaign") {
        this.showMenu = false;
      } else if (to.path == "/sales/salesReps/campaignDetail") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      this.showGuide = this.$route.query.isNew == 1 ? true : false;

      console.log(to, from);
    },
  },
  computed: {
    currentPath: {
      get() {
        return this.$route.path;
      },
    },
  },

  created() {
    this.showMenu = this.showMenu2();
    this.showGuide = localStorage.getItem("showGuide") == "1" ? true : false;
  },
  methods: {
    closeGuide() {
      this.showGuide = false;
      localStorage.removeItem("showGuide");
    },
    goback() {
      this.$router.go(-1);
    },
    showMenu2() {
      if (this.$route.path == "/sales/salesReps/dialogue") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/createCampaign") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/campaignDetail") {
        return false;
      } else {
        return true;
      }
    },
    goPage () {
      window.open('https://youtu.be/l-YBhSImsDs?si=DHBD-zTBpsIbSLC8')
    }
  },
};
</script>
<style scoped lang="less">
.nav {
  width: 100%;
  padding-top: 7px;
  padding-left: 30px;
  border-top: #d1d1d1 solid 1px;
  li {
    height: 42px;
    line-height: 42px;
    padding: 0 12px;
    margin-right: 88px;
    display: inline-block;
    a {
      color: #000;
    }
    border-bottom: #fff 2px solid;
  }
  .selected {
    border-bottom: #0e756a 2px solid;
    a {
      font-weight: bold;
    }
  }
  background: #ffffff;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.15);
}
.nav,
.back {
  left: 254px;
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 999;
}
.back {
  border-top: #d1d1d1 solid 1px;
  color: #0e756a;

  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  background: #fff;
  span {
    cursor: pointer;
  }
}
.mask_bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  .caption {
    position: fixed;
    background: #fff;
    border-radius: 5px;
    height: 42px;
    line-height: 42px;
    padding: 0 12px;
    margin-right: 88px;
    display: inline-block;
    border-bottom: #fff 2px solid;
    color: #000;
    left: 420px;
    top: 73px;
    .text {
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      border-bottom: #0e756a 2px solid;
      font-weight: bold;
    }
    .des {
      color: #fff;
      font-size: 14px;
      position: absolute;
      font-weight: bold;
      width: 250px;
      margin-left: -12px;
      padding-top: 10px;
      line-height: 30px;
    }
  }
  .btn {
    width: 400px;
    position: fixed;
    top: 134px;
    left: 729px;
    z-index: 1000;
    // display: flex;
    // align-items: top;
    .guideBtn {
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      color: #fefefe;
      background-color: #0e756a;
      border-color: #0e756a;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      border-radius: 4px;
      display: inline-block;
    }
    .des {
      margin-top: 6px;
      display: block;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
    }
  }
  .title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .guide-con {
    margin-left: 20px;
    min-width: 400px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    position: fixed;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 300px;
    .con-click {
      cursor: pointer;
      font-size: 16px;
    }
  }
}
</style>
